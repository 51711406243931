.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.pac-container {
  z-index: 99999 !important;
}
.map-container .map {
  position: relative !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-content {
  box-sizing: border-box;
  margin: 10px auto auto;
}
@media only screen and (max-width: 767px) {
  .main-content {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (min-width: 768px) {
  .main-content {
    min-width: 750px;
    padding: 0 50px;
    width: auto;
  }
}

@media only screen and (min-width: 992px) {
  .main-content {
    width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .main-content {
    width: 85%;
  }
}
.gm-style .gm-style-iw {
  font-weight: 400;
}
.gm-style .gm-style-iw-t::after {
  background: #2d2f33;
  box-shadow: -2px 2px 2px 0 rgb(178 178 178 / 40%);
  content: "";
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 15px;
}

.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: #2d2f33;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
  width: 212px;
  margin-right: 12px;
  margin-bottom: 10px;
}

.dark .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background: #555;
}

.gm-style .gm-style-iw-t {
  display: none;
}
